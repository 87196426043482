import {Form, Popconfirm, Row, Col,  Input, Upload, Popover, DatePicker, message, Button, Card, Select, notification} from 'antd';
import React, {Component,useState, useEffect} from "react";
import jsonfile from './exepense.json';
import {Link} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import Config from "../../../../util/config";
import axios from "../../../../util/Api";
import moment from "moment";
import 'moment/locale/es-us';
import {useHistory} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import IntlMessages from "../../../../util/IntlMessages";


const NewExpenses = (props) => {

    const formRef = React.createRef();

    const [loading, setLoading] = useState([]);
    const [entryDate, setEntryDate] = useState(null);
    const [month, setMonth] = useState(null);
    const [total, setTotal] = useState(0);
    const [uploadarea, setUploadArea] = useState("localexpense");
    const [expenseItems, setOfficeExpenseItems] = useState([]);
    const [officeExpnese, setOfficeExpense] = useState([]);
    const [inputList, setInputList] = useState([]);


    const [form] = Form.useForm();

    let endpoint = jsonfile.urls.edit;
    const redirectto = "../../../"+jsonfile.urls.list;
    const history = useHistory();

    const handleChange=(e)=> {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    const userID = props.match.params.id;
    let userData = [];
    console.log("Userid : "+props.match.params.id);
    let isEdit = 0;
    if(userID > 0) {
        isEdit = 1;
    }
    let Title = jsonfile.addtitle;
    if(isEdit === 1 ) {
       // userData = RemoteJson(jsonfile.urls.view+"/"+userID);
        Title = jsonfile.edittitle;
        endpoint = jsonfile.urls.edit+"/"+userID;
    }


    const onFinish = (values) => {
        setLoading({loading:true})
        console.log("Input Data : "+values);
        console.log("ExpenseData : "+JSON.stringify(inputList));
        document.getElementById("loader").style.display = "block";
        const newArray = inputList.filter(value => JSON.stringify(value) !== '{}');

        const wk = values["month"];
        // console.log("Selected Week : "+ wk);
        const mnts = CisUI().ParseDateFormatFromMonth(wk);
        // console.log("Month :"+mnts);
        values['month'] = mnts;
        // console.log("AfterFilter : "+inputList);
        values['expenses'] = newArray;
        let msg = "Sorry! request not processed, please try again";
        axios.post(Config.apiserver + endpoint, values)
            .then(res => {
                console.log(res);
                document.getElementById("loader").style.display = "none";
                if(res.data.status === 1) {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                    //history.push(redirectto);
                    history.goBack();
                }
                else {
                    notification.warning({
                        message: 'Alert',
                        type : "warning",
                        description: res.data.msg
                    });
                }
                setLoading({loading:false});
            })
            .catch(errors => {
                console.log(errors);
                // msg = (errors);
                setLoading({loading:false});
                //setResponse([]);
                document.getElementById("loader").style.display = "none";
            });
    };

    let dataOptions = [];

    const ExpenseDatails = () => {
        document.getElementById("loader").style.display = "block";
        axios.get(Config.apiserver+"donation/lcexpneseopenbymonth/"+userID)
            .then((res) => {
                // console.log(res.data);
                if(res.data.status === 1) {
                    setOfficeExpenseItems(res.data.data);
                    const dataOptions = res.data.data;
                    let dataex = [];
                    let area = "";
                    dataOptions.map((value,index) => {
                        const atchment = [...value.allattachment];
                        area = value.area;
                        dataex[index] = {
                            id: value.id,
                            account_id : value.account_id,
                            account_number : value.account_number,
                            account_name : value.account_name,
                            name : value.account_number+"-"+value.account_name,
                            expensetype : value.account_id,
                            amount : value.amount,
                            allattachment: value.allattachment,
                            note : value.notes,
                            files : [],
                            file_location : []
                        };
                    });

                    if(area === 'Local') {
                        setUploadArea("localexpense");
                    }
                    else if(area === 'District') {
                        setUploadArea("districtexpense");
                    }
                    else if(area === 'Region') {
                        setUploadArea("regionexpense");
                    }

                    setInputList(dataex);
                    setTotal(res.data.total);
                    setMonth(res.data.month);
                    setEntryDate(res.data.entryDate);
                    //console.log(dataex);
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("loader").style.display = "none";
            });
    }


    // const callOfficeExpense = () => {
    //     axios.get(Config.apiserver+"donation/expensetype?area=6")
    //         .then((res) => {
    //             // console.log(res.data);
    //             if(res.data.status === 1) {
    //                 dataOptions = res.data.data;
    //                 setOfficeExpense(res.data.data);
    //                 const dataex = [];
    //                 dataOptions.map((value,index) => {
    //                     dataex[index] = {
    //                         account_id : value.id,
    //                         account_number : value.account_number,
    //                         account_name : value.account_name,
    //                         name : value.expense_type,
    //                         expensetype : value.id,
    //                         amount : '',
    //                         note : '',
    //                         files : [],
    //                         file_location : []
    //                     };
    //                 });
    //                 setInputList(dataex);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    useEffect(() => {
        //callOfficeExpense();
        ExpenseDatails();
    }, []);

    // useEffect(() => {
    //     setInputList(dataOptions);
    // }, []);


    const onFileChange = (info, index) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        const uplod = {
            name: 'file',
            action: Config.apiserver+"fileupload",
            headers: {
                'Authorization': token,
            },
            data : {
                'file' : formData
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
                if (!isJpgOrPng) {
                    message.error('You can only upload jpg, png,and pdf file only!');
                }
                return isJpgOrPng;
            },
            onChange(info) {
                formData.append('file', info.file);
                formData.append('type', "localexpense");
                formData.append('file_index', index);
                if (info.file.status === 1) {
                    message.success(`${info.file.name} file uploaded successfully`);
                    if (info.file.status !== 'uploading') {
                        console.log(info.file, info.fileList);
                        const data = info.file.data;
                        const list = [...inputList];
                        list[index]["file_location"] = data.location || "";
                        list[index]["file_id"] = data.id || 0;
                        setInputList(list);
                    }
                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
        //uplod;
    }

    const token = localStorage.getItem('token');
    let formData = new FormData();
    let fileUID = "";
    const uplod = {
        name: 'file',
        action: Config.apiserver+"fileupload",
        method : 'post',
        headers: {
            'Authorization': token,
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
            if (!isJpgOrPng) {
                message.error('You can only upload jpg, png,and pdf file only!');
            }
            return isJpgOrPng;
        },
        onChange(info) {
            formData.append('file', info.file);
            fileUID = info.file.uid;
            console.log("index : "+info.file.uid);
            console.log(JSON.stringify(info));
            if(info.file.status === "done") {
                if (info.file.xhr.status === 200) {
                    console.log("ServerResponse : "+info.file.response);
                    const data = info.file.response;
                    if(data.status === 1) {
                        const list = [...inputList];
                        let files = list[data.line_index].files || [];
                        let file_location = list[data.line_index].file_location || [];
                        files.push(data.data.id);
                        file_location.push(data.data.location);
                        list[data.line_index].files = files;
                        list[data.line_index].file_location = file_location;
                        setInputList(list);
                        console.log("Files : "+JSON.stringify(list[data.line_index]));
                        message.success(`${info.file.name} file uploaded successfully`);
                    }

                } else {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        }
    }



    //console.log("UserData : "+userData);

    const handleInputChange = (e, index) => {
        if(e && e.target) {
            const {name, value} = e.target;

            const list = [...inputList];

            //list[index]["expensetype"] = inputList[index].expensetype;
            list[index][name] = value;
            setInputList(list);
            //list[index]["expensetype"] = dataOptions[index].id;
            //setInputList(list);
            // console.log(JSON.stringify(inputList));
        }
        else {
            const list = [...inputList];
            //list[index]["expensetype"] = e;
            ////setInputList(list);
        }
    };

// handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

// handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { }]);
    };

    const cancel = (e) => {
        console.log(e);
        //message.error("Data is not deleted.");
    }

    const confirmDelete = (fileid,lineid,fileindex) => () => {
        console.log(fileid);
        //message.error("Data is deleted.");
        // message.error("Data is not deleted.");
        const vl = {
            fileid : fileid,
            lineid : lineid
        };
        console.log(vl);
        if(fileid > 0 && lineid > 0) {
            const vl = {
                fileid : fileid,
                lineid : lineid
            };
            document.getElementById("loader").style.display = "block";
            axios.post(Config.apiserver + "filedelete", vl)
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.status === 1) {
                        const files = res.data.files;
                        let list = [...inputList];
                        list[fileindex]["allattachment"] = files;
                        setInputList(list);

                        notification.warning({
                            message: 'Alert',
                            type : "success",
                            description: res.data.msg
                        });

                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type : "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("loader").style.display = "none";
                });
        }
        else {
            message.error("Invalid request");
        }

    }




    return (
        <Card title={"Modify Expenses"}>
            {/*{*/}
            {/*    isEdit === 1*/}
            {/*        ? CisUI().addAction("../../"+jsonfile.urls.list)*/}
            {/*        : CisUI().addAction("../"+jsonfile.urls.list)*/}
            {/*}*/}
            {CisUI().showLoading}
            <Form
                form={form}
                ref={formRef}
                {...CisUI().formItemLayout}
                name="input"
                className="ant-advanced-search-form"
                onFinish={onFinish}
            >

                { month !== null ?
                <div className="row">
                    <div className="col-md-2">
                        <label>Entry Date : {CisUI().DateFormat(entryDate)}</label>
                    </div>
                    <div className="col-md-3">
                        <Form.Item
                            label={<IntlMessages id="Month" />}
                            name="month"
                            initialValue={month !== null ? moment(month, 'YYYY-MM') : null}
                            rules={[
                                {
                                    required: true,
                                    message: "Month is required"
                                },
                            ]}
                        >
                            <DatePicker picker="month" format={"YYYY-MM"} className="gx-mb-3 gx-w-100"
                                        placeholder="Select a month" />

                        </Form.Item>

                    </div>
                </div> : "" }

                <Row gutter={24}>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Account Name</th>
                            <th>Amount</th>
                            <th>Note</th>
                            <th>Attachment</th>
                        </tr>
                        </thead>

                        {inputList.map((x, i) => {
                            return (
                                <>
                                    <tr>
                                        <td>{x.name}
                                            <input type="hidden" id={"expensetype_"+x.id} name="expensetype" value={x.id} />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="amount"
                                                id={"amount_"+x.id}
                                                placeholder="Amount"
                                                value={x.amount}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                className="ant-input"
                                                name="note"
                                                id={"note_"+x.id}
                                                placeholder="Enter a note"
                                                value={x.note}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </td>
                                        <td>
                                            {
                                                x.allattachment.map((files,fl)=>{

                                                    const content = (
                                                        <div>
                                                            <p>
                                                                <a href={Config.fileserver+""+files.location} target="_blank">
                                                                <button className="btn btn-primary btn-circle">
                                                                    <i className="fas fa-envelope-open"/>
                                                                </button> Open </a>
                                                            </p>
                                                            <p>
                                                                <Link to="#">
                                                                    <Popconfirm title="Are you sure you want to delete this file?" onConfirm={confirmDelete(files.id,x.id,i)} onCancel={cancel} okText="Yes"
                                                                                cancelText="No">
                                                                        <button className="btn btn-danger btn-circle">
                                                                            <i className="fas fa-trash"/>
                                                                        </button> <IntlMessages id="Delete" />
                                                                    </Popconfirm>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    );
                                                    return <Popover placement="leftBottom" title={"Actions"} content={content} trigger="click">
                                                        <Button type="primary" className="btn btn-primary btn-circle"><i className="fa fa-file"></i></Button>
                                                    </Popover>
                                                    // return <a href={Config.fileserver+""+files.location} target="_blank" className="btn btn-primary btn-sm btn-circle">
                                                    //     <i className="fa fa-file"></i>
                                                    // </a>
                                                })
                                            }
                                            <Upload data= {
                                                {
                                                    file : formData,
                                                    type : uploadarea,
                                                    file_id : fileUID,
                                                    row_id : x.id,
                                                    line_index : i
                                                }
                                            } {...uplod}>
                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                            </Upload>

                                            {/*<Upload onChange={info => onFileChange(info, i)}>*/}
                                            {/*    <Button icon={<UploadOutlined />}>Click to Upload</Button>*/}
                                            {/*</Upload>*/}
                                        </td>
                                    </tr>
                                </>
                            );
                        })}

                    </table>

                </Row>

                <Row gutter={24}>
                    <Col lg={24} md={24} sm={12} xs={24} style={{textAlign: 'center', marginTop : '30px'}}>
                        <Button id="submitbutton" type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button type="info" onClick={() => history.goBack()} htmlType="back">Cancel</Button>
                    </Col>
                </Row>

            </Form>


        </Card>
    );
};

export default NewExpenses;