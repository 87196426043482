import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import Config from "../util/config";
import {CisUI} from "../util/CISUI";

import {useHistory} from "react-router-dom";

import axios from "util/Api";


const Forgotpassword = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const history = useHistory();

  const onFinish = values => {
    var endpoint = "forgotpassword";
    var redirectto = "/passwordreset";

    axios.post(Config.apiserver + endpoint, values)
        .then(res => {
          console.log(res)
          if(res.data.status === 1) {
            localStorage.setItem("fuser",values["email"]);
            notification.success({
              message: 'Success',
              type : "success",
              description: res.data.msg
            });
            history.push(redirectto);
          }
          else {
            notification.warning({
              message: 'Alert',
              type : "warning",
              description: res.data.msg
            });
          }
          document.getElementById("loader").style.display = "none";
        })
        .catch(errors => {
          console.log(errors);
        });

  };

  useEffect(() => {
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [authUser]);

  return (
    <div className="gx-app-login-wrap">
      {CisUI().showLoading}
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src="https://via.placeholder.com/272x395" alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.forgotPassword"/></h1>
              {/*<p><IntlMessages id="app.userAuth.bySigning"/></p>*/}
              {/*<p><IntlMessages id="app.userAuth.getAccount"/></p>*/}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/coplogo.png"/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                initialValue=""
                rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                <Input placeholder={"Enter your email/username"} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="Submit"/>
                </Button>
                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                id="Back to Login"/></Link>
              </Form.Item>
            </Form>
            <div><h5>For New Users. Contact your District Pastor for access. </h5></div>
          </div>
          <InfoView/>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
