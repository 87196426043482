import React from "react";
import {Route, Switch} from "react-router-dom";

import CompanyList from "../../containers/yotta/company/cominfo/list";
import NewCompany from "../../containers/yotta/company/cominfo/new";
import NewCompanyType from "../../containers/yotta/company/type/new";
import CompanyTypes from "../../containers/yotta/company/type/list";
import EditAssetDist from "../../containers/yotta/asset_management/asset_distribution/EditAssetDistribution";
import AutoLogoutTimer from "../../util/AutoLogoutTimer";

const Company = ({match}) => (
    <Switch>
            <Route path={`${match.url}/companylists`} component={CompanyList} />
            <Route path={`${match.url}/newcompany`} component={NewCompany} />
            <Route path={`${match.url}/companyedit/:id`} component={NewCompany} />
            <Route path={`${match.url}/newcomtype`} component={NewCompanyType} />
            <Route path={`${match.url}/alltype`} component={CompanyTypes} />
            <Route path={`${match.url}/comtypeedit/:id`} component={NewCompanyType} />
    </Switch>
);

export default Company;
