import React from "react";
import {Route, Switch} from "react-router-dom";

import RegionList from "../../containers/yotta/settings/region/RegionList";
import NewRegion from "../../containers/yotta/settings/region/NewRegion";
import DistrictList from "../../containers/yotta/settings/district/DistrictList";
import NewDistrict from "../../containers/yotta/settings/district/NewDistrict";
import LocalList from "../../containers/yotta/settings/local/LocalList";
import NewLocal from "../../containers/yotta/settings/local/NewLocal";
import OfficeList from "../../containers/yotta/settings/office/OfficeList";
import NewOffice from "../../containers/yotta/settings/office/NewOffice";
import FundProgramList from "../../containers/yotta/settings/fund_program/FundProgramList";
import NewFundProgram from "../../containers/yotta/settings/fund_program/NewFundProgram";
import FunctionalExpenseList from "../../containers/yotta/settings/functional_expense/FunctionalExpenseList";
import NewFunctionalExpense from "../../containers/yotta/settings/functional_expense/NewFunctionalExpense";
import ApproverList from "../../containers/yotta/settings/approver/ApproverList";
import NewApprover from "../../containers/yotta/settings/approver/NewApprover";
import BankAccountList from "../../containers/yotta/settings/bank_account/BankAccountList";
import NewBankAccount from "../../containers/yotta/settings/bank_account/NewBankAccount";
import ArticleList from "../../containers/yotta/settings/article/ArticleList";
import NewArticle from "../../containers/yotta/settings/article/NewArticle";
import EditArticle from "../../containers/yotta/settings/article/EditArticle";
import NewApporverMulty from "../../containers/yotta/settings/approver/NewApporverMulty";
import MyApproverList from "../../containers/yotta/settings/approver/myapprovalpermissions";
import ImportOpeningBalance from "../../containers/yotta/settings/importopeningbalance";
import Newclass from "../../containers/yotta/settings/classification/newclass";
import Classlist from "../../containers/yotta/settings/classification/classlist";
import ClassEdit from "../../containers/yotta/settings/classification/classedit";

const CRM = ({match}) => (
    <Switch>
        <Route path={`${match.url}/regions`} component={RegionList}/>
        <Route path={`${match.url}/newregion`} component={NewRegion}/>
        <Route path={`${match.url}/regionedit/:id`} component={NewRegion}/>
        <Route path={`${match.url}/district`} component={DistrictList}/>
        <Route path={`${match.url}/newdistrict`} component={NewDistrict}/>
        <Route path={`${match.url}/districtedit/:id`} component={NewDistrict}/>
        <Route path={`${match.url}/local`} component={LocalList}/>
        <Route path={`${match.url}/newlocal`} component={NewLocal}/>
        <Route path={`${match.url}/localedit/:id`} component={NewLocal}/>
        <Route path={`${match.url}/offices`} component={OfficeList}/>
        <Route path={`${match.url}/newoffice`} component={NewOffice}/>
        <Route path={`${match.url}/officeedit/:id`} component={NewOffice}/>
        <Route path={`${match.url}/fundprograms`} component={FundProgramList}/>
        <Route path={`${match.url}/newfundprogram`} component={NewFundProgram}/>
        <Route path={`${match.url}/fundprogramedit/:id`} component={NewFundProgram}/>
        <Route path={`${match.url}/functionalexpenses`} component={FunctionalExpenseList}/>
        <Route path={`${match.url}/newfunctionalexpense`} component={NewFunctionalExpense}/>
        <Route path={`${match.url}/functionalexpenseedit/:id`} component={NewFunctionalExpense}/>
        <Route path={`${match.url}/approvers`} component={ApproverList}/>
        {/*<Route path={`${match.url}/newapprover`} component={NewApprover}/>*/}
        <Route path={`${match.url}/newapprover`} component={NewApporverMulty}/>
        <Route path={`${match.url}/apvperm`} component={MyApproverList}/>
        <Route path={`${match.url}/approveredit/:id`} component={NewApprover}/>
        <Route path={`${match.url}/bankaccounts`} component={BankAccountList}/>
        <Route path={`${match.url}/newbankaccount`} component={NewBankAccount}/>
        <Route path={`${match.url}/bankaccountedit/:id`} component={NewBankAccount}/>
        <Route path={`${match.url}/supportarticles`} component={ArticleList}/>
        <Route path={`${match.url}/newsupportarticle`} component={NewArticle}/>
        <Route path={`${match.url}/supportarticleedit/:id`} component={EditArticle}/>
        <Route path={`${match.url}/importopeningbalance`} component={ImportOpeningBalance}/>
        <Route path={`${match.url}/newclass`} component={Newclass}/>
        <Route path={`${match.url}/classlist`} component={Classlist}/>
        <Route path={`${match.url}/classedit/:id`} component={ClassEdit}/>
    </Switch>
);

export default CRM;
