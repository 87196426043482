import React, {useState, useEffect} from "react";
import { Row, Col, notification} from 'antd';
import Auxiliary from "util/Auxiliary";
import Widget from "components/Widget/index";
import { MailOutlined,MessageOutlined,BellOutlined,UnorderedListOutlined   } from '@ant-design/icons';
import LineIndicator from "./LineIndicator";
import {Bar, BarChart, Area, AreaChart, Legend, CartesianGrid,  XAxis, YAxis, ResponsiveContainer, Tooltip} from "recharts";
import axios from "../../../util/Api";
import Config from "../../../util/config";
import {CisUI} from "../../../util/CISUI";
import {Link} from "react-router-dom";
import Metrics from "../../../components/Metrics";

import CanvasJSReact from './../../canvasjs/canvasjs.react';

import PropTypes from 'prop-types';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import IntlMessages from "../../../util/IntlMessages";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const AdminDashboard = (props) => {

    const userLocal = localStorage.getItem('userData') || "";
    let  userID = 0;
    let userName = "";
    let user = {
        id : 0,
        name : ""
    };
    if(userLocal !== "") {
        user = JSON.parse(userLocal);
        userID = user['id'];
        userName = user['name'];
    }

    const [incomeExpense,setInocmeExpnese] = useState([]);
    const [state,setState] = useState({
        totalMember : 0,
        totalPayingMember : 0,
        totalNonPayingMember : 0,
        payingPercent : 0,
        nonPayingPercent : 0,
        totalTithe : 0,
        titheCompare : [],
        currentMonthCollection : {
            tithe : 0,
            mission : 0,
            others : 0,
            total : 0
        },
        previousMonthCollection : {
            tithe : 0,
            mission : 0,
            others : 0,
            total : 0
        },
        thisyearDonation : [],
        grossTithe : 0,
        allowableExpense : 0,
        netTithe : 0,
        devFund : 0,
        devFundTitle : "",
        kpi : [],
        bankReconcile : [],
        highestTithe : {
            name : "",
            amount: 0
        },
        highestMission : {
            name : "",
            amount: 0
        },
        titheByRegion : [],
        incomeByRestriction : [],
        cashBalance : 0,
        exenseYT : 0,
        receiveableBalance : 0,
        payableBalance : 0,
        notification : {
            notification: 0,
            bill: 0,
            po: 0,
            task: 0,
        },
    });

    useEffect(() => {
        getDashboardData();
    }, []);


    const getDashboardData = () => {
        document.getElementById("loader").style.display = "none";
        axios.get(Config.apiserver + "dashboard",null,CisUI().HeaderRequest)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setInocmeExpnese(res.data.incomeExpense);
                    const totalMember = res.data.totalMember
                    const totalPayingMember = res.data.totalPayingMember
                    const totalNonPayingMember = res.data.totalNonPayingMember
                    const payingPercent = ((totalPayingMember * 100) / totalMember).toFixed(2);
                    const nonPayingPercent = ((totalNonPayingMember * 100) / totalMember).toFixed(2);

                    setState({
                        totalMember : totalMember,
                        totalPayingMember : totalPayingMember || 0,
                        payingPercent : payingPercent || 0,
                        totalNonPayingMember : totalNonPayingMember || 0,
                        nonPayingPercent : nonPayingPercent || 0,
                        totalTithe : res.data.totalTithe,
                        titheCompare : res.data.titheCompare,
                        currentMonthCollection : res.data.currentMonthCollection,
                        previousMonthCollection : res.data.previousMonthCollection,
                        thisyearDonation : res.data.thisyearDonation,
                        grossTithe : res.data.grossTithe,
                        allowableExpense : res.data.allowableExpense,
                        netTithe : res.data.netTithe,
                        devFund : res.data.devFund,
                        devFundTitle : res.data.devFundTitle,
                        kpi : res.data.kpi,
                        bankReconcile : res.data.bankReconcile,
                        highestTithe : res.data.highestTithe,
                        highestMission : res.data.highestMission,
                        titheByRegion : res.data.titheByRegion,
                        incomeByRestriction : res.data.incomeByRestriction,
                        exenseYT : res.data.exenseYT,
                        cashBalance : res.data.cashBalance,
                        receiveableBalance : res.data.receiveableBalance,
                        payableBalance : res.data.payableBalance,
                        notification : res.data.notification,
                    });
                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                // document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                // console.log(errors.response.data.errors);
                console.log(errors);
                // document.getElementById("loader").style.display = "none";
            });
    }

    CanvasJS.addColorSet("brandedset",["#2F4F4F","#008080","#2E8B57","#3CB371","#90EE90"]);

    const colorPlates = ["#142850","#27496d","#0c7b93","#00a8cc","#8566aa","#6983aa","#8ec6c5","#4d4c7d","#2c003e","#7f78d2","#1eb2a6"]

    const colors = scaleOrdinal(schemeCategory10).range();

    const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

    const TriangleBar = (props) => {
        const { fill, x, y, width, height } = props;

        return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };

    TriangleBar.propTypes = {
        fill: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    const titheByRegion = {
        animationEnabled: true,
        colorset : colorPlates,
        axisX: {
            title: ""
        },
        axisY: {
            title: "Amount",
        },
        toolTip: {
            shared: true
        },
        legend: {
            cursor: "pointer",
        },
        labelLine : false,
        theme: "light2", //"light1", "dark1", "dark2"
        data: [{
            type: "column",
            name: "Tithe",
            showInLegend: false,
            color: "#00A8CCFF",
            yValueFormatString: "$ #,##0.#",
            dataPoints: state.titheByRegion
        }]
    }

    const QuickRatio = {
        animationEnabled: true,
        title: {
            text: "Quick Ratio"
        },
        subtitles: [{
            text: "1.10% Positive",
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
        }],
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "#,###'%'",
            colorSet: "brandedset",
            dataPoints: [
                {name: "Cash Equivalent", y: 5},
                {name: "Marketable Security", y: 30},
                {name: "Accounts receivable", y: 25},
                {name: "Current Liabilities", y: 40}
            ]
        }]
    }

    const IncomeByRestriction = {
        animationEnabled: true,
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            yValueFormatString: "$#,###",
            colorSet: "brandedset",
            dataPoints: state.incomeByRestriction
        }]
    }

    const width = 400;

    return (
        <Auxiliary>
            {CisUI().showLoading}
            <Row>
                <Col span={24}>
                    <div className="gx-card">
                        <div className="gx-card-body">
                            <Row>
                                <Col xl={6} lg={12} md={12} sm={12} xs={24}>

                                    <div className="gx-wel-ema gx-pt-xl-2">
                                        <h1 className="gx-mb-3"><IntlMessages id={"Welcome"} /> {user.name}!</h1>
                                        <p className="gx-fs-sm gx-text-uppercase"><IntlMessages id={"You Have"} /></p>
                                        <ul className="gx-list-group">
                                            <li>
                                                <MessageOutlined />
                                                <span><Link to={"../vendor/pendingbill"}>{state.notification.bill} Bill Approval request(s)</Link></span>
                                            </li>
                                            <li>
                                                <BellOutlined />
                                                <span><Link to={"../user/notification"}>{state.notification.notification} notifications</Link></span>
                                            </li>
                                            <li>
                                                <MailOutlined />
                                                <span><Link to={"../purchase/pendingpo"}>{state.notification.po} Pending PO's</Link></span>
                                            </li>
                                            <li>
                                                <MailOutlined />
                                                <span><Link to={"../crm/tasklist"}>{state.notification.task} Task</Link></span>
                                            </li>
                                        </ul>
                                    </div>

                                </Col>

                                <Col xl={6} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
                                    <div className="gx-site-dash gx-mb-2 gx-pt-3 gx-pt-sm-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-sm-4"><IntlMessages id={"Member Count"} /></h6>
                                        <ul className="gx-line-indicator">
                                            <li>
                                                <LineIndicator width="100%" title="Total Adult Members" color="cyan" value={CisUI().getNumber(state.totalMember || 0)} />
                                            </li>
                                            <li>
                                                <LineIndicator width={(state.payingPercent || 0)+"%"} title={"Total Paying Members ("+CisUI().getNumber(state.totalPayingMember || 0)+")"} color="geekblue" value={(state.payingPercent || 0)+"%"} />
                                            </li>
                                            <li>
                                                <LineIndicator width={(state.nonPayingPercent || 0)+"%"} title={"Total Non-paying Members ("+CisUI().getNumber(state.totalNonPayingMember || 0)+")"} color="orange" value={(state.nonPayingPercent || 0)+"%"} />
                                            </li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-visit-col">
                                    <div className="gx-site-dash gx-pr-xl-5 gx-pt-3 gx-pt-xl-0 gx-pt-xl-2">
                                        <h6 className="gx-text-uppercase gx-mb-2 gx-mb-xl-4"><IntlMessages id={"Income & Expenditure"} /></h6>
                                        <ResponsiveContainer width="100%" height={140}>
                                            <AreaChart data={incomeExpense}
                                                       margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                                <defs>
                                                    <linearGradient id="colorIncome" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#27496d" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.4}/>
                                                    </linearGradient>
                                                    <linearGradient id="colorExpnese" x1="0" y1="0" x2="0" y2="1">
                                                        <stop offset="5%" stopColor="#bf163f" stopOpacity={0.8}/>
                                                        <stop offset="95%" stopColor="#bf163f" stopOpacity={0.4}/>
                                                    </linearGradient>
                                                </defs>
                                                <XAxis dataKey="name" />
                                                <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                                <Area type="monotone" dataKey="Income" label={"Income"} stroke="#8884d8" fillOpacity={1} fill="url(#colorIncome)" />
                                                <Area type="monotone" dataKey="Expense" label={"Expense"} stroke="#82ca9d" fillOpacity={1} fill="url(#colorExpnese)" />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xl={8} lg={24} md={8} sm={24} xs={24}>
                    <Metrics title={<IntlMessages id={"Total Gross Tithe"} />}>
                        <Row>
                            <Col xl={11} lg={12} md={24} sm={12} xs={12}>
                                <h4 className="gx-mb-1">{CisUI().getCurrencyFormated1(state.totalTithe)}</h4>
                                <p className="gx-mb-md-0 gx-text-light"><IntlMessages id={"Total Tithes YTD"} /></p>
                            </Col>
                            <Col xl={13} lg={12} md={24} sm={12} xs={12}>

                                <ResponsiveContainer className="gx-barchart" width="100%" height={70}>
                                    <BarChart data={state.titheCompare}
                                              margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
                                        <Bar dataKey="lastYear" stackId="a" fill="#27496d" barSize={width <= 575 ? 4 : 8}/>
                                        <Bar dataKey="thisYear" stackId="a" fill="#FE9E15" barSize={width <= 575 ? 4 : 8}/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </Metrics>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <Metrics>
                        <div className="gx-customers">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><IntlMessages id={"Title"} /></th>
                                        <th className="currency"><IntlMessages id={"Previous Month"} /></th>
                                        <th className="currency"><IntlMessages id={"Current Month"} /></th>
                                        <th className="currency"><IntlMessages id={"Differences"} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Net Tithe and Offering</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.tithe)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.tithe - state.currentMonthCollection.tithe)}</td>
                                    </tr>
                                    <tr>
                                        <td>Add: Missions Offering</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.mission)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.mission - state.currentMonthCollection.mission)}</td>
                                    </tr>
                                    <tr>
                                        <td>Add: Other Contributions</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.others)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.others)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.others - state.currentMonthCollection.others)}</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.currentMonthCollection.total)}</td>
                                        <td className="currency">{CisUI().getCurrencyFormated1(state.previousMonthCollection.total - state.currentMonthCollection.total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Metrics>
                </Col>
                <Col xl={8} lg={12} md={8} sm={24} xs={24}>
                    <Metrics styleName={`gx-card-full`} title="This year : All Donations">
                        <Row>
                            <Col lg={24} md={24} sm={15} xs={15}>
                                <ResponsiveContainer width="100%" height={103}>
                                    <AreaChart data={state.thisyearDonation}
                                               margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                        <defs>
                                            <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
                                                <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>
                                                <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="name" />
                                        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip formatter={(value) => CisUI().getCurrencyFormated1(value)} />
                                        <Area type="monotone" dataKey='total' strokeWidth={0} stackId="2" stroke='#867AE5' fill="url(#color1)" fillOpacity={1}/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </Metrics>
                </Col>

                <Col xl={8} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-media-body">
                                        <p>
                                            {
                                                CisUI().getUserInfo('comarea') === 6 ? "Net Income" : "Highest Net Tithe"
                                            }
                                        </p>
                                        <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.highestTithe.amount)}</h4>
                                        <p className="gx-mb-0 text-center" style={{color: '#27496d'}}>{state.highestTithe.name}</p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-media-body">
                                        <p>Highest Missions Offering</p>
                                        <h4 className="gx-fs-xl text-center gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.highestMission.amount)}</h4>
                                        <p className="text-center gx-mb-0" style={{color: '#27496d'}}>{state.highestMission.name}</p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                    </Row>
                    <div className="row">
                        <Widget title="Income by Restrictions" styleName="gx-card-tabs">
                            <CanvasJSChart options = {IncomeByRestriction} />
                        </Widget>
                        {/*<Widget title="Financial Ratios" styleName="gx-card-tabs" >*/}
                        {/*    <CanvasJSChart options = {QuickRatio} />*/}
                        {/*</Widget>*/}
                    </div>
                </Col>

                <Col xl={16} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-1">
                    <Row>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-mr-2 gx-mr-xxl-3">
                                        <i className={`icon icon-diamond gx-fs-icon-lg`} style={{color: '#27496d'}}/>
                                    </div>
                                    <div className="gx-media-body">
                                        <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.grossTithe)}</h4>
                                        <p className="gx-mb-0" style={{color: '#27496d'}}>
                                            {
                                                CisUI().getUserInfo('comarea') <= 3 ? "Tithe Collected" : CisUI().getUserInfo('comarea') === 4 ? "Gross Tithe from District" : CisUI().getUserInfo('comarea') === 5 ? "Gross Tithe From Local" : CisUI().getUserInfo('comarea') === 6 ? "Gross Tithe" : ""
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-mr-2 gx-mr-xxl-3">
                                        <i className={`icon icon-tasks gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                    </div>
                                    <div className="gx-media-body">
                                        <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}} >{CisUI().getCurrencyFormated1(state.allowableExpense)}</h4>
                                        <p className="gx-mb-0" style={{color: '#27496d'}} >Allowable Expenses</p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-mr-2 gx-mr-xxl-3">
                                        <i className={`icon icon-team gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                    </div>
                                    <div className="gx-media-body">
                                        <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1 " style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.netTithe)}</h4>
                                        <p className="gx-mb-0" style={{color: '#27496d'}}>
                                            {
                                                CisUI().getUserInfo('comarea') <= 3 ? "Net Tithe From Region" : CisUI().getUserInfo('comarea') === 4 ? "Net to Nation" : CisUI().getUserInfo('comarea') === 5 ? "Net to Region" : CisUI().getUserInfo('comarea') === 6 ? "Net to District" : ""
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                    <div className="gx-mr-2 gx-mr-xxl-3">
                                        <i className={`icon icon-files gx-fs-icon-lg`} style={{color: '#27496d'}} />
                                    </div>
                                    <div className="gx-media-body">
                                        <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.devFund)}</h4>
                                        <p className="gx-mb-0" style={{color: '#27496d'}}>{state.devFundTitle}</p>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        {
                            CisUI().getUserInfo('comarea') < 6 ?

                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                <h3>
                                    {
                                        CisUI().getUserInfo('comarea') <= 3 ? "Current Month Tithe By Region" : CisUI().getUserInfo('comarea') === 4 ? "Current Month Tithe By District" : CisUI().getUserInfo('comarea') === 5 ? "Current Month Tithe By Local" : CisUI().getUserInfo('comarea') === 6 ? "Current Month Gross Tithe" : ""
                                    }
                                </h3>
                                <CanvasJSChart options = {titheByRegion} />
                                {/*<ResponsiveContainer width="100%" height={300}>*/}
                                {/*    <AreaChart*/}
                                {/*        baseValue="none"*/}
                                {/*        data={state.titheByRegion}*/}
                                {/*               margin={{top: 0, right: 0, left: 0, bottom: 0}}>*/}
                                {/*        <defs>*/}
                                {/*            <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">*/}
                                {/*                <stop offset="5%" stopColor="#27496d" stopOpacity={0.9}/>*/}
                                {/*                <stop offset="95%" stopColor="#00a8cc" stopOpacity={0.9}/>*/}
                                {/*            </linearGradient>*/}
                                {/*        </defs>*/}
                                {/*        <XAxis dataKey="label" tick={{ angle: -45 }} textAnchor="end" />*/}
                                {/*        <YAxis tickFormatter={(value) => new Intl.NumberFormat('en', { notation: "compact", compactDisplay: "short" }).format(value)} />*/}
                                {/*        <CartesianGrid strokeDasharray="3 3" />*/}
                                {/*        <Tooltip formatter={(value) => CisUI().getCurrencyFormated1(value)} />*/}
                                {/*        <Area type="monotone" dataKey='Tithe' strokeWidth={0} stackId="2" stroke='#867AE5' fill="url(#color1)" fillOpacity={1}/>*/}
                                {/*    </AreaChart>*/}
                                {/*</ResponsiveContainer>*/}
                            </Widget>
                        </Col>
                        : "" }
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Widget title="Current Financial Status">
                                <div className="row">
                                    <div className="col-md-3">
                                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                <div className="gx-media-body">
                                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.cashBalance)}</h4>
                                                    <p className="gx-mb-0" style={{color: '#27496d'}}>Cash Equ. Balance</p>
                                                </div>
                                            </div>
                                        </Widget>
                                    </div>
                                    <div className="col-md-3">
                                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                <div className="gx-media-body">
                                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.exenseYT)}</h4>
                                                    <p className="gx-mb-0" style={{color: '#27496d'}}>Total Expenses</p>
                                                </div>
                                            </div>
                                        </Widget>
                                    </div>
                                    <div className="col-md-3">
                                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                <div className="gx-media-body">
                                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.receiveableBalance)}</h4>
                                                    <p className="gx-mb-0" style={{color: '#27496d'}}>Accounts Receivable</p>
                                                </div>
                                            </div>
                                        </Widget>
                                    </div>
                                    <div className="col-md-3">
                                        <Widget styleName={`gx-card-full gx-p-3 gx-bg-white`}>
                                            <div className="gx-media gx-align-items-center gx-flex-nowrap">
                                                <div className="gx-media-body">
                                                    <h4 className="gx-fs-xl gx-font-weight-semi-bold gx-mb-1" style={{fontSize: '18px',color: '#00a8cc'}}>{CisUI().getCurrencyFormated1(state.payableBalance)}</h4>
                                                    <p className="gx-mb-0" style={{color: '#27496d'}}>Accounts Payable</p>
                                                </div>
                                            </div>
                                        </Widget>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th><IntlMessages id={"Indicator"} /></th>
                                                    <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Current Month"} /></th>
                                                    <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Previous Month"} /></th>
                                                    <th style={{textAlign: 'right', paddingRight: '10px'}}><IntlMessages id={"Change"} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                state.kpi.map((items,index) => {
                                                    return <tr>
                                                        <td>{items.name}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                            {
                                                                items.current > items.last ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < items.last ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                            }
                                                            {items.current}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.last}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>
                                                            {
                                                                items.change > 0 ? <i style={{color : 'green'}} className="fas fa-caret-up" /> : items.change < 0 ? <i style={{color : 'red'}} className="fas fa-caret-down" /> : ""
                                                            }
                                                            {items.change}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Widget title="Bank Reconciliation Summary">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Account</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Account Balance</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Balance</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Last Reconcile Date</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Bank Balance</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Balance as of</th>
                                                <th style={{textAlign: 'right', paddingRight: '10px'}}>Items to Match</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                state.bankReconcile.map((items,index) => {
                                                    return <tr>
                                                        <td>{items.name}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.balance)}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.lastBalance)}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.lastDate !== "" ? CisUI().DateFormat(items.lastDate) : ""}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.bankBalance)}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{items.balanceDate !== '' ? CisUI().DateFormat(items.balanceDate): ""}</td>
                                                        <td style={{textAlign: 'right', paddingRight: '10px'}}>{CisUI().getCurrencyFormated1(items.itemToMatch)}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Widget>
                        </Col>
                        {/*<Col xl={16} lg={16} md={16} sm={24} xs={24}>*/}
                        {/*    <TicketList/>*/}
                        {/*</Col>*/}
                        {/*<Col xl={8} lg={8} md={8} sm={24} xs={24}>*/}
                        {/*    <TaskByStatus/>*/}
                        {/*</Col>*/}
                        {/*<Col xl={24} lg={24} md={24} sm={24} xs={24}>*/}
                        {/*    <Overview/>*/}
                        {/*</Col>*/}
                    </Row>
                </Col>
            </Row>
        </Auxiliary>
    );
};

export default AdminDashboard;
