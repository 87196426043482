import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import Config from "../util/config";
import axios from "util/Api";
import {useHistory} from "react-router-dom";


const PasswordReset = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(({auth}) => auth.authUser);

    const history = useHistory();

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = values => {
        console.log("finish",values)
        //dispatch(userSignIn(values));

        var endpoint = "passwordreset";
        var redirectto = "/signin";
        var user = localStorage.getItem("fuser");
        values["upd"] = user;

        if(values["newpass"] === values["repass"]) {
            axios.post(Config.apiserver + endpoint, values)
                .then(res => {
                    console.log(res)
                    if(res.data.status === 1) {
                        notification.success({
                            message: 'Success',
                            type : "success",
                            description: res.data.msg
                        });
                        localStorage.removeItem("fuser");
                        history.push(redirectto);
                    }
                    else {
                        notification.warning({
                            message: 'Alert',
                            type : "warning",
                            description: res.data.msg
                        });
                    }
                    document.getElementById("loader").style.display = "none";
                })
                .catch(errors => {
                    console.log(errors);
                });
        }
        else {
            notification.success({
                message: 'Alert',
                type : "warning",
                description: "Retype password not matched"
            });
        }

    };

    useEffect(() => {
        if (authUser !== null) {
            props.history.push('/');
        }
    }, [authUser]);

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg">
                            <img src="https://via.placeholder.com/272x395" alt='Neature'/>
                        </div>
                        <div className="gx-app-logo-wid">
                            <h1><IntlMessages id="app.userAuth.resetPassword"/></h1>
                            {/*<p><IntlMessages id="app.userAuth.bySigning"/></p>*/}
                            {/*<p><IntlMessages id="app.userAuth.getAccount"/></p>*/}
                        </div>
                        <div className="gx-app-logo">
                            <img alt="example" src="/assets/images/coplogo.png"/>
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <Form
                            initialValues={{ remember: true }}
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="gx-signin-form gx-form-row0">

                            <Form.Item
                                initialValue=""
                                rules={[{ required: true, message: 'Verification code is required' }]} name="code">
                                <Input placeholder={"Enter Verification code"} />
                            </Form.Item>
                            <Form.Item
                                initialValue=""
                                rules={[{ required: true, message: 'New password is required' }]} name="newpass">
                                <Input type="password" placeholder={"Enter New Password"} />
                            </Form.Item>
                            <Form.Item
                                initialValue=""
                                rules={[{ required: true, message: 'Retype password is required' }]} name="repass">
                                <Input type="password" placeholder={"Retype New Password"} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="Submit"/>
                                </Button>
                                <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                                id="Back to Login"/></Link>
                            </Form.Item>
                        </Form>
                    </div>
                    <InfoView/>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
