module.exports = {
  // apiserver: 'https://fmsapi.yottaerp.com/api/',
  // baseurl: 'https://fms.yottaerp.com/',
  // baseurl: 'http://localhost:3000/',
  // apiserver: 'http://localhost:8000/api/',

  baseurl: 'https://staging.mycopusaa.com/',
  fileserver: 'https://sandboxapi.mycopusa.com/public',
  // fileserver: 'https://api.mycopusa.com/public',
  // baseurl: 'https://office.mycopusa.com/',
  // baseurl: 'https://live.mycopusa.com/',
  apiserver: 'https://sandboxapi.mycopusa.com/api/',
  // apiserver: 'https://api.mycopusa.com/api/',

  // profile_pic_url: 'http://localhost:8000/upload/profile/',
  // profile_pic_url: 'https://fmsapi.yottaerp.com/public/upload/profile/',
  // profile_pic_url: 'https://sandboxapi.mycopusa.com/public/upload/profile/',
  profile_pic_url: 'https://api.mycopusa.com/public/upload/profile/',
  // support_article_image: 'https://fmsapi.mycopusa.com/public/upload/support_article/',
  // support_article_image: 'https://api.mycopusa.com/public/upload/support_article/',
  support_article_image: 'https://api.mycopusa.com/public/upload/support_article/',
  // support_article_image: 'http://localhost:8000/upload/support_article/',

  logourl : '/assets/images/coplogo.png',
  print_logourl : '/assets/images/cop_nlogo.png',
  company_name : 'The Church of Pentecost U.S.A., Inc',
  address : 'Wayne, New Jersey, USA',
  city : 'Wayne',
  state : 'New Jersey',
  zipcode : '11111',
  phone : "",
  email : "info@copusa.org",
  website : "https://copusa.org",
  footerText: 'All rights reserved by - The Church of Pentecost U.S.A., Inc © 2022',

}
