import React, {useState} from "react";
import { Form, Col, Input ,Card, Collapse} from 'antd';
import jsonfile from './creditnote.json';
import {Link, useHistory} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import ActionButton from '../../../../util/actionbutton';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";

const Panel = Collapse.Panel;


const CreditNoteList = () => {
    const history = useHistory();
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle: ''
    });


    const headers = {
        id: {
            invisible: true
        },
        company_name: {
            text: <IntlMessages id="Company Name"/>,
            sortable: true,
            filterable: false,
        },
        customer_name: {
            text: <IntlMessages id="Customer Name"/>,
            sortable: true,
            filterable: false,
        },
        invoice_number: {
            text: <IntlMessages id="Invoice Number"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                <Link to={"cnotedetails/"+row.id}>{value}</Link>
            )
        },
        created_at: {
            text: <IntlMessages id="Created At"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        total: {
            text: <IntlMessages id="Total"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: false,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        receiveable: {
            text: <IntlMessages id="Receivable"/>,
            sortable: true,
            filterable: false
        },
        accounts: {
            text: <IntlMessages id="Accounts"/>,
            sortable: true,
            filterable: false
        },
        status: {
            text: <IntlMessages id="Status"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getOrderStatus(value)
            )
        },
        actions: {
            text: <IntlMessages id="Actions"/>,
            sortable: false,
            filterable: false,
            transform: (value, idx, row) => (
                <>
                    {ActionButton(row.id,jsonfile.urls,jsonfile.urls.list)}
                </>
            ),
        },
    };

    const orderedHeaders = jsonfile.listView.title;

    const [form] = Form.useForm();

    const onFinish = values => {
        // console.log('Received values of form: ', values);
        const data = {
            ...values,
            // 'purchase_date' : values['purchase_date'].format("YYYY-MM-DD"),
        }
        // console.log(data);
        const qs = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        const qsup = qs.replaceAll("undefined","");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?"+qsup);
        const newURL = Config.apiserver+jsonfile.urls.list+"/?"+qsup;
        setState({reqURL: newURL});
    };

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header={<IntlMessages id="Advance Search" />}  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.input,"",1,"date",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {CisUI().listAction("../"+jsonfile.urls.add, "", state.reqURL, "data", 'all_invoice', jsonfile, state.subtitle, 'landscape')}
        </>
    );

    return (
        <>
            {searchView}
            <Card title={<IntlMessages id={jsonfile.listtitle} />} extra={getExtra}>
                {/*{CisUI().listAction(jsonfile.urls.add)}*/}
                <SmartDataTable
                    name='test-fake-table'
                    data={state.reqURL}
                    dataRequestOptions={CisUI().HeaderRequest}
                    dataKey="data"
                    headers={headers}
                    orderedHeaders={orderedHeaders}
                    hideUnordered={CisUI().hideUnordered}
                    className={CisUI().sematicUI.table}
                    filterValue={state.filterValue}
                    perPage={CisUI().perPage}
                    sortable
                    withLinks
                    withHeader
                    loader={CisUI().loader}
                    parseBool={{
                        yesWord: 'Yes',
                        noWord: 'No',
                    }}
                    parseImg={{
                        style: {
                            border: '1px solid #ddd',
                            borderRadius: '50px',
                            padding: '3px',
                            width: '30px',
                            height: '30px'
                        },
                    }}
                    emptyTable={CisUI().emptyTable}
                />
            </Card>
        </>
    );
};

export default CreditNoteList;