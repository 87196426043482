import React, {useState, useEffect} from "react";
import { Form, Col, Input, Collapse ,Card, notification} from 'antd';
import jsonfile from './trialbalance.json'
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import {CisUI} from '../../../../util/CISUI';
import AdvancedSearch from '../../../../util/AdvancedSearch';
import SmartDataTable from 'react-smart-data-table';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import IntlMessages from "util/IntlMessages";
import Config from "../../../../util/config";
import axios from "../../../../util/Api";

const Search = Input.Search;
const Panel = Collapse.Panel;

const TrialBalance = () => {
    const value = 0;
    const [state,setState] = useState({
        reqURL: Config.apiserver+jsonfile.urls.list,
        filtervalue : '',
        subtitle : '',
    });

    const [header,setHeader] = useState("");
    const [period,setPeriod] = useState("");

    const [hide,setHide] = useState([]);
    const [company,setCompany] = useState({});

    const ref = React.createRef();
    const formRef = React.createRef();

    const [data,setData] = useState([]);

    useEffect(() => {
        if(CisUI().getUserInfo('com_id') > 0) {
            getLedgerData();
        }
    }, []);


    const getLedgerData = (url = "") => {
        document.getElementById("loader").style.display = "block";
        let urls = state.reqURL;
        if(url !== "") {
            urls = url;
        }
        axios.get(urls)
            .then(res => {
                console.log(res)
                if (res.data.status === 1) {
                    setData(res.data.data);
                    setCompany(res.data.company);
                    setHeader(res.data.company.legal_name);

                } else {
                    notification.warning({
                        message: 'Alert',
                        type: "warning",
                        description: res.data.msg
                    });
                }
                document.getElementById("loader").style.display = "none";
            })
            .catch(errors => {
                //console.log(errors.response.data.errors);
                //document.getElementById("loader").style.display = "none";
            });
    }

    const history = useHistory();

    const headers = {
        // _id: {
        //     text: '#',
        //     invisible: false,
        //     filterable: false,
        //     transform: (value) => `${++value}`,
        // },
        id: {
            invisible : true
        },
        date: {
            text: <IntlMessages id="Date"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().DateFormat(value)
            )
        },
        debit: {
            text: <IntlMessages id="Debit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        },
        credit: {
            text: <IntlMessages id="Credit"/>,
            sortable: true,
            filterable: true,
            transform: (value, idx, row) => (
                CisUI().getCurrency(value)
            )
        }
    };

    const orderedHeaders = jsonfile.listView.title;

    const getFields = () => {
        const count = expand ? 10 : 6;
        const children = [];
        const inputs = jsonfile.input;

        inputs.map((list, index) => {
            if(list.isFilterable == 1) {
                children.push(
                    <Col lg={6} md={6} sm={12} xs={24} key={index} style={{display: index < count ? 'block' : 'none'}}>
                        <div className="gx-form-row0">
                            <Form.Item
                                name={`${list.name}`}
                                label={`${list.label}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Input placeholder={list.name} />
                            </Form.Item>
                        </div>
                    </Col>,
                );
            }
        });
        return children;
    };

    const [expand, setExpand] = useState(false);
    const [form] = Form.useForm();

    const onFinish = values => {
        let wkd = "";
        let wk = "";
        let dt = "";
        const qs = Object.keys(values).map(key => {
                if (key === 'date') {
                    wk = formRef.current.getFieldValue('date');
                    const dt = CisUI().ParseDateFormat(values[key]);
                    setPeriod("Between "+CisUI().DateFormat(dt[0])+" and "+CisUI().DateFormat(dt[1]));
                    return `${key}=${dt}`
                } else if (key === 'month') {
                    wk = formRef.current.getFieldValue('month');
                    // console.log("Selected Week : "+ wk);
                    wkd = CisUI().getMonthTitle(formRef.current.getFieldValue('month'));
                    return `${key}=${wkd[1]}`
                } else {
                    return `${key}=${values[key]}`
                }
            }
        ).join('&');

        const qsup = qs.replaceAll("undefined", "");
        //reqURL = reqURL+"/?"+qsup;
        history.push("?" + qsup);
        const newURL = Config.apiserver + jsonfile.urls.list + "?" + qsup;
        setState({reqURL: newURL});

        getLedgerData(newURL);
    };

    const handleOnFilter = ({ target: { name, value } }) => {
        //filterValue = value;
        console.log("filteringwith :"+ value);
        setState({filtervalue : "test"});
    }

    let  searchView = "";
    if(jsonfile.has_search) {
        searchView = <Card className="advance-search-card">
            <Collapse
                accordion
                expandIconPosition="right"
                defaultActiveKey={['1']} >
                <Panel header="Advance Search"  key="1">
                    <Form
                        key={5}
                        form={form}
                        {...CisUI().formItemLayout}
                        ref={formRef}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                    >
                        {AdvancedSearch(jsonfile.search,"",1,"",CisUI().PeriodFiled(form))}
                    </Form>
                </Panel>
            </Collapse>
        </Card>
    }

    const getExtra = (
        <>
            {/*<Search*/}
            {/*    placeholder='Filter...'*/}
            {/*    name='filtervalue'*/}
            {/*    onSearch={handleOnFilter}*/}
            {/*    onChange={handleOnFilter}*/}
            {/*    style={{width: 200}}*/}
            {/*/>*/}
            {CisUI().listActionReport("","",state.reqURL,"data","trial_balance",jsonfile,"Trial Balance")}
        </>
    );


    const setCollapse = (id) => {
        console.log("Slcct: ", id);
        if (hide.indexOf(id) > -1) {
            var index = hide.indexOf(id); // Let's say it's Bob.
            hide.splice(index, 1);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-right")
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);
            var lastYear = document.getElementsByClassName("lasty_" + id);

            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'inherit';
                //row[i].removeAttribute('style');
                row[i].style.display = 'none';
            }

            for (i = 0; i < thisYear.length; i++) {
                thisYear[i].removeAttribute('style');
                lastYear[i].removeAttribute('style');
            }
        } else {
            const hd = hide;
            hd.push(id);
            setHide(hd);
            document.getElementById("icon_" + id).setAttribute("class", "fas fa-chevron-down");
            var row = document.getElementsByClassName("disp_" + id);
            var thisYear = document.getElementsByClassName("this_" + id);
            var lastYear = document.getElementsByClassName("lasty_" + id);
            var i;
            for (i = 0; i < row.length; i++) {
                //row[i].style.display = 'none';
                row[i].removeAttribute('style');
            }

            for (i = 0; i < thisYear.length; i++) {
                //row[i].style.display = 'none';
                thisYear[i].style.display = "none";
                lastYear[i].style.display = "none";
            }
        }
        console.log("hides : ", hide);
    }

    let totalDebit = 0;
    let totalCredit = 0;

    return (
        <>
            {searchView}
            <Card extra={getExtra}>
                {CisUI().showLoading}

                <div className="table-responsive">
                    <div style={{textAlign : 'center'}}>
                        <h2>{header}</h2>
                        <h4>Trial Balance</h4>
                        <h4>{period}</h4>
                    </div>

                    <table className="statement table mx-auto w-auto">
                        <thead>
                            <tr>
                                <th style={{width: '400px'}}>Account</th>
                                <th style={{textAlign : 'right', width : '120px'}}>Debit</th>
                                <th style={{textAlign : 'right', width : '120px'}}>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((itmes,index)=> {
                                var row = [];
                                if(itmes.type === 'Debit') {
                                    totalDebit += parseFloat(itmes.balance);
                                    row.push(<tr>
                                        <td style={{fontWeight: 'normal'}}>
                                            {itmes["childtree"].length > 0 ?
                                                <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                       onClick={() => setCollapse(itmes.id)}
                                                                                       className="fas fa-chevron-right"/> </span>
                                                : <></>}
                                            {itmes.account}
                                        </td>
                                        <td style={{textAlign : 'right', fontWeight: 'normal'}}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.balance)}</span></td>
                                        <td style={{textAlign : 'right', fontWeight: 'normal'}}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(0)}</span></td>
                                    </tr>)
                                }
                                else {
                                    totalCredit += parseFloat(itmes.balance);
                                    row.push(<tr>
                                        <td className="clmintent" style={{fontWeight: 'normal'}} >
                                            {itmes["childtree"].length > 0 ?
                                                <span style={{marginLeft: '-10px'}}><i id={"icon_" + itmes.id}
                                                                                       onClick={() => setCollapse(itmes.id)}
                                                                                       className="fas fa-chevron-right"/> </span>
                                                : <></>}
                                            {itmes.account}
                                        </td>
                                        <td style={{textAlign : 'right', fontWeight: 'normal'}}><span className={"this_" + itmes.id} >{CisUI().getCurrencyFormated1(0)}</span></td>
                                        <td style={{textAlign : 'right', fontWeight: 'normal'}}><span className={"lasty_" + itmes.id} >{CisUI().getCurrencyFormated1(itmes.balance)}</span></td>
                                    </tr>)
                                }

                                itmes["childtree"].map((itm,ix)=>{
                                    if(itm.type === 'Debit') {
                                        //totalDebit += parseFloat(itm.balance);
                                        row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                            <td style={{fontWeight: 'normal'}}><div className="subItemText">{itm.account}</div></td>
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(itm.balance)}</td>
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(0)}</td>
                                        </tr>)
                                    }
                                    else if(itm.type === 'total') {
                                        //totalDebit += parseFloat(itm.balance);
                                        row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                            <td style={{fontWeight: 'bold'}}><div className="subItemText">{itm.account}</div></td>
                                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(itm.debit)}</td>
                                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(itm.credit)}</td>
                                        </tr>)
                                    }
                                    else {
                                       // totalCredit += parseFloat(itm.balance);
                                        row.push(<tr className={"disp_" + itmes.id} style={{display: "none"}}>
                                            <td className="clmintent" style={{fontWeight: 'normal'}} ><div className="subItemText">{itm.account}</div></td>
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(0)}</td>
                                            <td style={{textAlign : 'right', fontWeight: 'normal'}}>{CisUI().getCurrencyFormated1(itm.balance)}</td>
                                        </tr>)
                                    }
                                });

                                return row;

                            })
                        }

                        <tr className="showTotalBorderDouble" >
                            <td className="clmintent" style={{fontWeight: 'bold'}} >Total = </td>
                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(totalDebit)}</td>
                            <td style={{textAlign : 'right', fontWeight: 'bold'}}>{CisUI().getCurrencyFormated1(totalCredit)}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>

            </Card>
        </>
    );
};

export default TrialBalance;